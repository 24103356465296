import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'tabs', loadChildren: './pages/tabs/tabs.module#TabsPageModule',canActivate:[AuthGuard] },
  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' , canActivate: [AuthGuard] },
  { path: 'profile', loadChildren: './pages/profile/profile.module#ProfilePageModule' , canActivate: [AuthGuard] },
  { path: 'signup', loadChildren: './pages/signup/signup.module#SignupPageModule' },
  { path: 'password-recovery', loadChildren: './pages/password-recovery/password-recovery.module#PasswordRecoveryPageModule' },
  {
    path: 'settings', children: [
      { path: '', loadChildren: './pages/settings/settings.module#SettingsPageModule' },
      { path: 'password-change', loadChildren: './pages/settings/password-change/password-change.module#PasswordChangePageModule' }
    ]
  },
  { path: 'notification', loadChildren: './pages/notification/notification.module#NotificationPageModule' },  {
    path: 'stand-up',
    loadChildren: () => import('./pages/stand-up/stand-up.module').then( m => m.StandUpPageModule)
  },
  {
    path: 'stand-ups',
    loadChildren: () => import('./pages/stand-ups/stand-ups.module').then( m => m.StandUpsPageModule)
  },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

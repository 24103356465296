import { Component } from '@angular/core';

import { Platform, NavController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import * as moment from "moment";
import { PushService } from './services/push.service';
import { EventsService } from './services/events.service';
import { ApiService } from './services/api.service';
import { ToastAlertService } from 'src/app/services/toast-alert.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public user : any;
  public role : any;
  public menuItemsUser:any;
  public helpme:boolean=false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navController: NavController,
    private menuController: MenuController,
    private events: EventsService,
    private storage: Storage,
    private api: ApiService,
    private pushService: PushService,
    private toast: ToastAlertService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.pushService.startUpCOnfig();
      this.storage.get('token').then((token)=>{
        if(token){
          this.pushService.updatePushToken();
          this.storage.get("ttl").then((ttl)=>{ 

            if((ttl != null && moment().isAfter(moment(ttl.created).add(ttl.ttl,"s")))&& ttl.ttl!=-1) {
              this.storage.clear()
              this.navController.navigateRoot('/login')
            }
            else {
              this.getUserData();
            }
          });
        } else {
          this.navController.navigateRoot('/login')
        }
        this.statusBar.styleLightContent();
        setTimeout(
          ()=>
            this.splashScreen.hide()
          ,2000
        )
  
        this.initializeEvents();
      })
    });
  }

  public goToUrl(url,type="push") {
    this.menuController.close();
    if(type=="push"){
      this.navController.navigateForward(url);
    }else{
      this.navController.navigateRoot(url);
    }
  }

  public initializeEvents() {
    let _this = this;
    this.events.getObservable("user:logged").subscribe((data) => {
        _this.getUserData();
    });
  }

  private async getUserData(data : any = null) {
    this.user = await this.storage.get("user");
    if(this.user != null) {
      if(this.api.IsAdmin(this.user.roles) || this.api.IsUser(this.user.roles)) {
        this.navController.navigateRoot('/tabs/dashboard')
        this.ShowItemMenu()
        this.menuController.enable(true)
      }
    }
  }
  InitializeMenu(){
    this.menuItemsUser = [
      {
        title: 'Inicio',
        url: '/tabs/dashboard',
        icon: 'home',
        navigationType: 'root',
        role: this.role
      },
      {
        title: 'Ajustes',
        url: '/settings',
        icon: 'settings',
        navigationType: 'root',
        role: this.role
      }
    ]
    return  this.menuItemsUser;
  }
  ShowItemMenu(){
    console.log(this.user)
    if(this.api.IsAdmin(this.user.roles)){
      this.role = 'User';
    }
    else if(this.api.IsUser(this.user.roles)){
      this.role = 'Admin';
    }
    this.InitializeMenu();
  }
  prepareURL(file: any, name: any) {
    var api = this.api;
    
    if (!file || !file.URL) {
      return "assets/img/no-image-found.png"
    }
    file.URL = this.GetFullUrl(file.URL, api);
    if (!file.resize) return file.URL

    name = this.CheckSizeName(name);
    return this.PepareUrlWithSufix(file.URL, name);

  }

  private GetFullUrl(url: string, api: any) {
    if (!(url.includes('https://') || url.includes('http://'))) {
      if (api) {
        if (api.hasOwnProperty('baseURL')) {
          url = api['baseURL'] + url;
        } else if (api.getBaseURL) {
          url = api['getBaseURL']() + url;
        }
      }
    }
    return url;
  }
  sizes: Array < String > = ['', 'avatar', 'thumb', 'small', 'medium', 'big'];
  private CheckSizeName(name) {
    if (this.sizes.findIndex(size => size === name) === -1) {
      name = '';
    }
    return name;
  }

  private PepareUrlWithSufix(url, name) {
    let newUrl = url.split('/').reverse();
    newUrl[0] = `${name}` + newUrl[0];
    newUrl = newUrl.reverse().join('/');
    return newUrl;
  }

  Help(){
    if(this.helpme) return true;

    this.helpme=true;

    this.api.post(`/Usuarios/${this.user.id}/helpMe`,{}).subscribe((data)=>{
      this.helpme=false;
      this.toast.ShowToast("En un momento llegará la ayuda")

    }, err => {
      console.error('error', err)
      this.toast.ShowToast("Ocurrió un error en el servidor")
    });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { ToastController, NavController, MenuController } from '@ionic/angular';

import { retryWhen, mergeMap, tap, delay, catchError } from 'rxjs/operators'; 
import { Observable, timer, throwError, of } from 'rxjs';

import { LoadingService } from './loading.service';
import { ToastAlertService } from './toast-alert.service';
import { environment } from '../../environments/environment';

const HTTP_HEADERS        = new HttpHeaders({'Content-Type': 'application/json'});
const RETRY_ATTEMPTS      = 5;
const RETRY_STATUS_CODES  = [ 408, 429, 504];
const RETRY_MILLISECONDS  = 10000;
const EXPIRED_CODE        = 401;


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public token: string = "";
  public debugMode: boolean = false;

  constructor(
    private http: HttpClient, 
    private storage: Storage,
    private navController: NavController,
    private toastController: ToastController,
    private menuController: MenuController,
    protected loading: LoadingService,
    public toastAlert: ToastAlertService,
    ) { 
    this.getToken();
    this.getDebugMode();
  }
  public get baseURL() {
    return environment.baseURL;
  }
  public get(endPoint: string, getVariables : any = {}, useToken:boolean = true, displayErrors: boolean = true): Observable<JSON> {
    let link: string = this.genLink(endPoint, useToken, getVariables)
    return this.processHttpRequest(this.http.get<JSON>(link, { headers: HTTP_HEADERS }), displayErrors);
  }

  public post(endPoint: string, body: object, useToken:boolean = true, displayErrors: boolean = true): Observable<JSON>{
    let link: string = this.genLink(endPoint, useToken);
    return this.processHttpRequest(this.http.post<JSON>(link, body, { headers: HTTP_HEADERS }), displayErrors);
  }

  public patch(endPoint: string, body: object, useToken:boolean = true, displayErrors: boolean = true): Observable<JSON>{
    let link: string = this.genLink(endPoint, useToken);
    return this.processHttpRequest(this.http.patch<JSON>(link, body, { headers: HTTP_HEADERS }), displayErrors);
  }

  public put(endPoint: string, body: object, useToken:boolean = true, displayErrors: boolean = true): Observable<JSON>{
    let link: string = this.genLink(endPoint, useToken);
    return this.processHttpRequest(this.http.put<JSON>(link, body, { headers: HTTP_HEADERS }), displayErrors);
  }

  public delete(endPoint: string, useToken:boolean = true, displayErrors: boolean = true): Observable<JSON>{
    let link: string = this.genLink(endPoint, useToken);
    return this.processHttpRequest(this.http.delete<JSON>(link, { headers: HTTP_HEADERS }), displayErrors);
  }

  public async setToken(token: string) {
    await this.storage.ready();
    await this.storage.set("token", token);
    this.token = token
  }


  private processHttpRequest(request: Observable<JSON>, displayErrors): Observable<JSON> {
    return request.pipe(
      retryWhen(errorResponse => this.retryOnConnectionError(errorResponse, displayErrors))
    );
  }

  private genLink(endPoint: string, useToken: boolean, getVars: any[] = []): string {

    if(useToken && this.token.length == 0){
      this.navController.navigateRoot('/tabs/dashboard');
      return;
    }

    useToken = useToken && this.token && this.token.length > 0;

    let baseURL = this.baseURL+ endPoint + "?";
    if(getVars != null && getVars.length > 0) {
      for(let variable of getVars) 
        baseURL = `${baseURL}${variable}=${getVars[variable]}&`;
    }

    return useToken ? baseURL + "access_token=" + this.token : baseURL;
  }

  private retryOnConnectionError(errorResponse: Observable<any>, displayErrors): Observable<any> {
    return errorResponse.pipe(
      mergeMap( (error, retryAttempts) => {

        if (error.status === EXPIRED_CODE) {
          this.storage.clear();
          this.navController.navigateRoot('/login');
          this.menuController.enable(false, 'mainMenu');
        }

        if(retryAttempts >= RETRY_ATTEMPTS || !RETRY_STATUS_CODES.find(code => error.status == code)) 
          return throwError(error)

        if(displayErrors)
          this.showToast(`Connection lost. Retrying in ${RETRY_MILLISECONDS/1000} seconds...`, 1000);

        return timer(RETRY_MILLISECONDS)
      })
    );
  }

  private async showToast(message: string, duration = 3000) {
    let toast = await this.toastController.create({
        message: message,
        duration: duration
    })
    await toast.present()
    return toast
}

  private async getDebugMode() {
    await this.storage.ready();
    this.debugMode = await this.storage.get("debugMode");
  }

  private async setDebugMode() {
    await this.storage.ready();
    this.debugMode = await this.storage.get("debugMode");
  }

  private async getToken() {
    await this.storage.ready();
    this.token = await this.storage.get("token");
  }

  public getBaseURL(){
    return this.baseURL
  }

  public async HandleAPIError(error) {
    this.loading.Show()
    if(error.error.error != undefined) {
        this.toastAlert.ShowToast(error.error.error.message);
    }
  }
  public IsAdmin(role){
    if(role.find(rol => rol.name == 'Admin') ){
        return true;
    }else return false;
  }
  public IsUser(role){
      if(role.find(rol => rol.name == 'User') ){
          return true;
      }else return false;
  }
  public IsClient(role){
      if(role.find(rol => rol.name == 'Client') ){
          return true;
      }else return false;
  }
  public IsComercial(role){
    if(role.find(rol => rol.name == 'Comercial') ){
        return true;
    }else return false;
  }
}
